<template>
  <div class="requisite-body">
    <div class="d-flex mb-2" style="border-bottom: 1px solid black; height: 55px">
      <h1 class="ma-3"> Реквизиты</h1>
      <v-btn
        color="#9DC033"
        dark
        @click="dialog = true"
        class="ml-3 mt-2"
      >
        Добавить
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="tableData"
      sort-by="calories"
      class="elevation-1 ma-5"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
    >
      <v-card
        class="crm-dialog"
      >
        <v-card-title >
          <v-card-title class="mb-2" >
            <div class="d-flex">
              <v-btn
                color="#9DC033"
                width="200"
                height="55"
                dark
                class="big-text"
                @click="save"
              >
                Сохранить
              </v-btn>
              <v-btn
                class="ml-3 big-text"
                color="#CFD0C9"
                width="200"
                height="55"
                dark
                @click="close"
              >
                Закрыть
              </v-btn>
            </div>
          </v-card-title>
        </v-card-title>

        <v-card-text>
          <div class="row--dense" style="border: 1px solid;">
            <div class="d-flex justify-space-between align-center"
                 style="border-bottom: 1px solid; height: 55px; max-height: 55px"
            >
              <div class="d-flex col-4 justify-start">
                <h1 class="pr-10">Поставщик</h1>
                <div>#ID</div>
              </div>
<!--              <div class="d-flex col-6 justify-space-between">-->
<!--                <div class="d-flex flex-column" >-->
<!--                  <span>Автор: {{-->
<!--                      (userCreated) ? userCreated : ''-->
<!--                    }}</span>-->
<!--                  <span>Дата и время:-->
<!--                  {{ (item && item.createdDate) ?
dateFormatter(item.createdDate) : ''}}</span>-->
<!--                </div>-->
<!--                <div class="d-flex flex-column">-->
<!--                  <span>Редактор:-->
<!--                    {{ (userModified) ? userModified : ''}}-->
<!--                  </span>-->
<!--                  <span>Дата и время:-->
<!--                    {{ (item && item.lastModifiedDate) ?
dateFormatter(item.lastModifiedDate) : ''}}-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->

            </div>
            <div>
              <v-form ref="form"
                      v-model="valid"
                      lazy-validation
              >
                <div>
                  <div class="flex-column col-5">
                    <div class="d-flex" v-for="col in cols" :key="col.prop">
                            <span class="col-4">
                              {{ col.label }}
                            </span>
                      <v-text-field
                        class="col-8"
                        solo
                        v-model="item[col.prop]"
                      />
                    </div>
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete">
      <v-card>
        <v-card-title class="headline">Вы действительно хотите удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">Да</v-btn>
          <v-btn color="warning" text @click="closeDelete">Отменить</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SuppliersMixin from '@/views/crm/suppliers/suppliers-mixin';

export default {
  name: 'RequisitesTable',
  props: ['supplierId'],
  mixins: [SuppliersMixin],
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        { sortable: false, text: 'Банк', value: 'bank' },
        { sortable: false, text: 'БИК', value: 'bik' },
        { sortable: false, text: 'Валюта', value: 'currency' },
        { sortable: false, text: 'IBAN', value: 'iban' },
        { sortable: false, text: 'КБе', value: 'kbe' },
        { sortable: false, text: 'Actions', value: 'actions' },
      ],
      tableData: [],
      editId: -1,
      cols: [
        { prop: 'bank', label: 'Банк' },
        { prop: 'bik', label: 'БИК' },
        { prop: 'currency', label: 'Валюта' },
        { prop: 'iban', label: 'IBAN' },
        { prop: 'kbe', label: 'КБе' },
      ],
      item: {
        bank: null,
        bik: null,
        currency: null,
        iban: null,
        kbe: null,
        createdDate: null,
        createdBy: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        supplierId: this.supplierId,
      },
      defaultItem: {
        bank: null,
        bik: null,
        createdBy: null,
        createdDate: null,
        currency: null,
        iban: null,
        kbe: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        supplierId: this.supplierId,
      },
      userCreated: null,
      userModified: null,
    };
  },
  computed: {
    formTitle() {
      return this.editId === -1 ? 'New Item' : 'Edit Item';
    },
  },

  watch: {
    editId: {
      handler(val) {
        if (val > -1) {
          this.setUserInformation();
        }
      },
    },
  },

  created() {
    this.getRequisites();
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getRequisites() {
      this.$api.get(`/api/v1/crm/supplier/requisites/${this.supplierId}`).then((response) => {
        this.tableData = [...response.content];
      });
    },

    editItem(item) {
      this.editId = item.id;
      this.item = { ...item };
      this.dialog = true;
    },

    deleteItem(item) {
      this.editId = item.id;
      this.item = { ...item };
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$api.delete(`/api/v1/crm/supplier/requisites/${this.editId}`).then(() => {
        this.getRequisites();
      }).finally(() => {
        this.closeDelete();
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // this.item = { ...this.defaultItem };
        this.editId = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.item = { ...this.defaultItem };
        this.editId = -1;
      });
    },

    save() {
      this.$loading(true);
      if (this.validate()) {
        let method;
        if (this.editId > -1) {
          method = 'put';
        } else {
          method = 'post';
        }
        this.$api[method]('/api/v1/crm/supplier/requisites', { ...this.item }).then(() => {
          this.reset();
          this.getRequisites();
          this.close();
        }).catch((e) => {
          this.$log.warn(e);
        }).finally(() => {
          this.$loading(false);
        });
      }
    },
  },
};
</script>

<style lang="scss">
.requisite-body {
  border: 1px solid black;
  background-color: #FFFFFF;
}

.crm-dialog {
  background-color: #F7F7F6;
  height: calc(100vh - 160px);
  padding: 0 30px 20px;
}
</style>
