<template>
  <v-container fluid>
    <crm-table-pageable-new
      :headers="headers"
      :data="data"
      :config="config"
      :loaded-options="options"
      :total-elements="totalElements"
      :total-pages="totalPages"
      @onChangeData="onChangeData"
      @handleActions="handle"
    ></crm-table-pageable-new>
    <contracts-dialog v-if="supplierId"
                      :supplier-id="supplierId"
                      :visible="contractDialog"
                      @closed="closeContractsDialog"/>
    <supplier-dialog :visible="supplierDialogOpened" :edit-id="editId"
                     @closed="closeSupplierDialog" @saved="saveSupplier"
    />
    <info-dialog v-if="infoDialog" :visible="infoDialog" :id="supplierId"
                 @closed="closeInfoDialog"
    />
  </v-container>
</template>

<script>
import SupplierDialog from '@/views/crm/suppliers/SuppliersDialog.vue';
import ContractsDialog from '@/views/crm/suppliers/ContractsDialog.vue';
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import InfoDialog from '@/views/crm/suppliers/InfoDialog.vue';
import {mapState} from 'vuex';

export default {
  name: 'index',
  components: {
    InfoDialog, ContractsDialog, SupplierDialog, 'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      supplierDialogOpened: false,
      infoDialog: false,
      headers: [
        {
          value: 'id', sortable: true, text: '#', type: 'defaultItem',
        },
        {
          value: 'name', sortable: true, text: 'Наименование', type: 'defaultItem',
        },
        {
          value: 'bin', sortable: false, text: 'БИН', type: 'defaultItem',
        },
        {
          value: 'telephone', sortable: false, text: 'Телефон', type: 'defaultItem',
        },
        {
          value: 'contactPerson', sortable: false, text: 'Контактное лицо', type: 'defaultItem',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          align: 'center',
          width: '100px',
          child: [
            {
              event: 'delete',
              icon: 'mdi-delete',
              visible: true,
            },
          ],
        },
      ],
      editId: -1,
      editedItem: null,
      defaultItem: {
        name: '',
      },
      contractDialog: false,
      supplierId: null,
      data: [],

      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/crm/suppliers',
      options: {
        page: 1,
        size: 10,
        sort: 'id,desc',
      },
      currentData: [],
    };
  },
  computed: {
    ...mapState(['user']),
    config() {
      return {
        add: this.canCRUD,
        addIcon: true,
        search: true,
        pageable: true,
      }
    },
    canCRUD() {

      return ["ROLE_CHIEF", "ROLE_CATEGORY_MANAGER_NON_FOOD"].some(role => {
          return this.user.roles.includes(role)
        }
      );
    },
    enums() {
      return this.$store.getters['entity/getEntity']('enums');
    },
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  mounted() {
    if (this.$route.params.id) {
      this.supplierId = this.$route.params.id;
      this.infoDialog = true;
    }
  },
  methods: {
    saveSupplier() {
      this.onChangeData(this.$route.query);
    },
    onChangeData(options) {
      let resultOptions = {...options};
      if (!Object.values(options).length) {
        resultOptions = {...this.options};
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {
      });
      this.options = {...resultOptions};
      this.getSuppliers();
    },
    getSuppliers() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$api.get(formattedUrl, {
        params: {
          page: params.page - 1,
          size: params.size,
          sort: params.sort,
          searchText: params.search,
        },
      }).then((response) => {
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
        this.data = response.content;
        const suppliers = this.$store.getters['entity/getEntity']('suppliers') || [];
        this.$store.dispatch('entity/actionSetEntity', {
          state: 'suppliers',
          data: suppliers.concat(response.content),
        });
      }).finally(() => {
        this.$loading(false);
      });
    },
    handle(element) {
      switch (element.type) {
        case 'create':
          if (!this.canCRUD) break;
          this.supplierDialogOpened = true;
          break;
        case 'info':
          this.infoItem(element);
          break;
        case 'delete':
          if (!this.canCRUD) break;
          this.deleteItem(element);
          break;
        case 'edit':
          if (!this.canCRUD) break;
          this.editItem(element);
          break;
        default:
          break;
      }
    },
    editItem(element) {
      this.editId = element.item.id;
      this.editedItem = {...element.item};
      this.supplierDialogOpened = true;
    },
    deleteItem(element) {
      this.editId = element.item.id;
      this.editedItem = {...element.item};
      this.$store.dispatch('tools/showConfirm', {
        title: 'Подтвердите',
        text: 'Вы действительно хотите удалить?',
      }).then(() => {
        this.deleteItemConfirm();
      }).catch(() => {
        this.closeDelete();
      });
    },
    deleteItemConfirm() {
      this.$api.delete(`/api/v1/crm/suppliers/${this.editId}`).then(() => {
        this.onChangeData(this.$route.query);
      }).finally(() => {
        this.closeDelete();
      });
    },
    close() {
      this.supplierDialogOpened = false;
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem};
        this.editId = -1;
      });
    },
    closeDelete() {
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem};
        this.editId = -1;
      });
    },
    save() {
      if (this.editId > -1) {
        Object.assign(this.tableData[this.editId], this.editedItem);
      } else {
        this.tableData.push(this.editedItem);
      }
      this.close();
    },
    closeSupplierDialog() {
      this.editId = -1;
      this.supplierDialogOpened = false;
    },
    closeContractsDialog() {
      this.contractDialog = false;
    },
    infoItem(element) {
      this.supplierId = element.item.id;
      this.$router.replace({
        name: 'supplier-info',
        params: {
          id: element.item.id,
        },
      });
      this.infoDialog = true;
    },
    closeInfoDialog() {
      this.supplierId = -1;
      this.infoDialog = false;
      this.$router.replace({
        name: 'suppliers',
      });
    },
  },
};
</script>

<style lang="scss">

</style>
