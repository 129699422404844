<template>
  <v-dialog
    v-model="visible"
    class="crm-dialog"
    @keydown.esc="$emit('closed')"
    @click:outside="$emit('closed')"
  >
    <cm-table-data :pcolumns="columns" :pconfig="config" :pheaders="headers"/>
  </v-dialog>
</template>

<script>
export default {
  name: 'ContractsDialog',
  props: ['supplierId', 'visible'],
  data() {
    return {
      headers: [
        { value: 'amount', sortable: false, text: 'Сумма договора' },
        { value: 'number', sortable: false, text: 'Номер договора' },
        { value: 'startDate', sortable: false, text: 'Дата начала' },
        { value: 'endDate', sortable: false, text: 'Дата окончания' },
        { value: 'signDate', sortable: false, text: 'Дата подписания' },
        { value: 'supplierId', sortable: false, text: 'Поставщик' },
        { value: 'status', sortable: false, text: 'Статус' },
        { value: 'organization', sortable: false, text: 'Организация' },
        { value: 'executor', sortable: false, text: 'Исполнитель' },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
      columns: [
        { value: 'amount', text: 'Сумма договора', component: 'cmTextField' },
        { value: 'number', text: 'Номер договора', component: 'cmTextField' },
        {
          value: 'startDate', text: 'Дата начала', component: 'cmDatePicker',
        },
        {
          value: 'endDate',
          text: 'Дата окончания',
          component: 'cmDatePicker',
        },
        {
          value: 'signDate',
          text: 'Дата подписания',
          component: 'cmDatePicker',
        },
        {
          value: 'supplierId', text: 'Поставщик', component: 'cmList', store: 'suppliers',
        },
        { value: 'executor', text: 'Исполнитель', component: 'cmTextField' },
        { value: 'organization', text: 'Организация', component: 'cmTextField' },
        {
          value: 'status', text: 'Статус', component: 'cmList', store: 'contractStatuses',
        },
      ],
      config: {
        title: {
          main: 'Договора',
          create: 'Создать',
          edit: 'Редактировать',
          delete: 'Удалить',
        },
        permissions: {
          create: true,
          edit: true,
          delete: true,
        },
        endpoints: {
          get: {
            method: 'get',
            url: `/api/v1/crm/supplier/contracts?supplierId=${this.supplierId}`,
            content: true,
          },
          create: {
            method: 'post',
            url: '/api/v1/crm/supplier/contracts',
          },
          edit: {
            method: 'put',
            url: '/api/v1/crm/supplier/contracts',
          },
        },
      },
    };
  },
};
</script>

<style scoped>

</style>
