import moment from 'moment';

export default {
  methods: {
    dateFormatter(val) {
      return moment(val).format('DD-MM-YYYY HH:mm');
    },
    getUserInfo(id) {
      return this.$api.get(`/api/v1/crm/users/${id}`);
    },
    setUserInformation() {
      this.getUserInfo(this.item.createdBy).then((response) => {
        this.$nextTick(() => {
          this.userCreated = `${response.firstName} ${response.lastName}`;
        });
      });
      this.getUserInfo(this.item.lastModifiedBy).then((response) => {
        this.$nextTick(() => {
          this.userModified = `${response.firstName} ${response.lastName}`;
        });
      });
    },
  },
};
