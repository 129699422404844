<template>
  <v-dialog v-model="visible"
            @keydown.esc="$emit('closed')"
            @click:outside="$emit('closed')">
    <requisite-form v-if="formVisible" :visible="formVisible" @closed="closeForm"
                    @saved="formSaved" :id="supplierId" :requisite="requisite">
    </requisite-form>
    <v-card class="supplier">
      <v-card-title class="supplier__title">
        <span class="supplier__title-font">Профиль продавца</span>
        <v-btn absolute right text fab @click="$emit('closed')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <template v-for="prop in Object.keys(supplierInfo)">
                <v-text-field
                  :key="prop"
                  readonly
                  :label="availableFields[prop]"
                  v-model="supplierInfo[prop]"
                ></v-text-field>
              </template>
            </v-col>
            <v-col cols="9">
              <v-card>
                <v-card-text>
                  <crm-table :config="config"
                             :data="requisites"
                             :headers="headers"
                             @handleActions="handle"/>
                </v-card-text>
              </v-card>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import crmTable from '@/components/crm-table/index.vue';
import RequisiteForm from '@/views/crm/suppliers/RequisiteForm.vue';
import { mapState } from 'vuex';

const suppliersType = {
  CORPORATION: 'АО',
  INDIVIDUAL: 'ИП',
  BUSINESS: 'ТОО'

}
export default {
  name: 'InfoDialog',
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    id: {
    },
  },
  components: {
    RequisiteForm,
    crmTable,
  },
  data() {
    return {
      suppliersType,
      supplierInfo: {},
      availableFields: {
        id: '#',
        type: 'Организационно правовая форма',
        name: 'Наименование',
        bin: 'ИИН / БИН',
        legalAddress: 'Юр. адрес',
        actualAddress: 'Фактический адрес',
        contactPerson: 'Контактное лицо',
        telephone: 'Телефон',
        email: 'Email',
      },
      config: {
        title: true,
        titleLabel: 'Реквизиты',
        add: true,
        addIcon: false,
        search: true,
        pageable: false,
        elevation: false,
      },
      requisites: [],
      headers: [
        {
          value: 'bank.name', text: 'Банк', type: 'defaultItem', sortable: false,
        },
        {
          value: 'kbe', text: 'Кбе', type: 'defaultItem', sortable: false,
        },
        {
          value: 'bank.bik', text: 'БИК', type: 'defaultItem', sortable: false,
        },
        {
          value: 'iban', text: 'IBAN', type: 'defaultItem', sortable: false,
        },
        {
          value: 'currency', text: 'Валюта счета', type: 'defaultItem', sortable: false,
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          align: 'center',
          width: '100px',
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
            {
              label: 'Удалить',
              event: 'delete',
              icon: 'mdi-delete',
              visible: true,
            },
          ],
        },
      ],
      formVisible: false,
      supplierId: null,
      requisite: {},
    };
  },
  watch: {
    id: {
      handler(val) {
        if (val > -1) {
          this.supplierId = val;
          this.getSupplierInfo(val);
          this.getRequisites(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapState(['user']),
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
  },
  methods: {
    getSupplierInfo(id) {
      let supplierId = id;
      if (typeof supplierId === 'string') {
        supplierId = parseInt(supplierId, 10);
      }
      const items = this.$store.getters['entity/getEntity']('suppliers');
      if (items && id) {
        const supplierInfo = items.find((i) => i.id === supplierId);
        Object.keys(this.availableFields).forEach((i) => {
          if (Object.keys(supplierInfo).includes(i)) {
            if (i === 'type') {
              this.supplierInfo[i] = this.suppliersType[supplierInfo[i]]
            } else {
              this.supplierInfo[i] = supplierInfo[i];
            }
          }
        });
      } else {
        this.$api.get('/api/v1/crm/suppliers').then((data) => {
          this.$store.dispatch('entity/actionSetEntity', {
            state: 'suppliers',
            data: Object.freeze(data.content),
          });
          const supplierInfo = data.content.find((i) => i.id === supplierId);
          Object.keys(this.availableFields).forEach((i) => {
            if (Object.keys(supplierInfo).includes(i)) this.supplierInfo[i] = supplierInfo[i];
          });
        });
      }
    },
    getRequisites(id) {
      this.$api.get(`/api/v1/crm/supplier/requisites/${id}`).then((res) => {
        this.requisites = [...res.content];
        this.$store.dispatch('entity/actionSetEntity', {
          state: `supplier/${id}/requisites`,
          data: Object.freeze(res.content),
        });
      });
    },
    handle(element) {
      switch (element.type) {
        case 'create':
          if (this.isChief) break;
          this.formVisible = true;
          break;
        case 'delete':
          if (this.isChief) break;
          this.removeRequisite(element);
          break;
        case 'edit':
          if (this.isChief) break;
          this.showRequisite(element);
          break;
        case 'info':
          this.showRequisite(element);
          break;
        default:
          break;
      }
    },
    closeForm() {
      this.formVisible = false;
      this.requisite = {};
    },
    formSaved() {
      this.closeForm();
      this.getRequisites(this.supplierId);
    },
    removeRequisite(element) {
      this.$api.delete(`/api/v1/crm/supplier/requisites/${element.item.id}`).then(() => {
        this.getRequisites(this.supplierId);
      });
    },
    showRequisite(element) {
      this.requisite = { id: element.item.id, mode: element.type };
      this.formVisible = true;
    },
  },
};
</script>

<style lang="scss">

.supplier {
  &__title {
    height: 72px;
  }
  &__title-font {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
  }
}
</style>
