<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="$emit('closed')"
    @click:outside="$emit('closed')"
    persistent
    max-width="60vw"
  >
    <v-card :loading="loading" >
      <v-card-title style="background-color: #E0E0E0">
        <h2 class="pa-3">Добавление поставщика</h2>
      </v-card-title>
      <v-card-text>
        <div class="row--dense dialog-body mt-5" >
          <div class="mt-3">
            <v-form ref="form"
                    v-model="valid"
                    lazy-validation
            >
              <div>
                <div class="flex-column col-8 col-lg-6">
                  <div class="d-flex" v-for="col in cols" :key="col.prop">
                    <v-text-field
                      v-if="col.prop === 'bin'"
                      class="col-8"
                      :label="col.label"
                      persistent-placeholder
                      placeholder=""
                      dense
                      :counter="12"
                      maxLength="12"
                      :rules="rules"
                      v-model="item[col.prop]"
                      v-mask="'############'"
                    />
                    <v-select
                      v-else-if="col.prop === 'type'"
                      class="col-8"
                      :label="col.label"
                      persistent-placeholder
                      placeholder=""
                      dense
                      v-model="item[col.prop]"
                      :rules="col.rules ? col.rules : [v => !!v]"
                      :items="typeList"
                      item-value="prop"
                      item-text="label"
                    >

                    </v-select>
                    <v-text-field
                      v-else-if="col.prop === 'telephone'"
                      class="col-8"
                      :label="col.label"
                      persistent-placeholder
                      placeholder=""
                      dense
                      prefix="+7"
                      v-model="item[col.prop]"
                      :rules="col.rules ? col.rules : [v => !!v]"
                      v-mask="' ### ###-##-##'">

                    </v-text-field>
                    <v-text-field
                      v-else
                      class="col-8"
                      :label="col.label"
                      persistent-placeholder
                      placeholder=""
                      dense
                      v-model="item[col.prop]"
                      :rules="col.rules ? col.rules : [v => !!v]"
                    />
                  </div>
                </div>
              </div>
            </v-form>
          </div>
          <div class="ma-5" v-if="editId > -1">
            <requisites-table :supplier-id="editId"/>
          </div>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-row style="height: 80px" justify="end" align="center" class="pa-2">
          <v-btn text
                 :disabled="loading"
                  @click="$emit('closed')">
            ОТМЕНИТЬ
          </v-btn>
          <v-btn  color="primary"
                 rounded style="margin-right: 20px;"
                 @click="save"
                 :disabled="loading"
                 :loading="loading"
          >СОХРАНИТЬ</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RequisitesTable from '@/views/crm/suppliers/RequisitesTable.vue';
import SuppliersMixin from '@/views/crm/suppliers/suppliers-mixin';

export default {
  name: 'SupplierDialog',
  components: { RequisitesTable },
  props: {
    visible: {
      default: () => false,
    },
    category: {
      default: () => {},
    },
    editId: {
      default: null,
    },
  },
  mixins: [SuppliersMixin],
  data() {
    return {
      valid: true,
      loading: false,
      cols: [
        { prop: 'type', label: 'Организационно-правовая форма' },
        { prop: 'name', label: 'Наименование' },
        { prop: 'bin', label: 'ИИН/БИН' },
        { prop: 'legalAddress', label: 'Юр.адрес' },
        { prop: 'actualAddress', label: 'Факт.адрес' },
        { prop: 'contactPerson', label: 'Контактное лицо' },
        {
          prop: 'telephone',
          label: 'Телефон',
          rules: [
            (v) => !!v && v.length === 14,
            (v) => /[-, ]/.test(v),
          ],
        },
        {
          prop: 'email',
          label: 'Email',
          rules: [
            (v) => !!v,
            (v) => /.+@.+\..+/.test(v),
          ],
        },
      ],
      item: {
        actualAddress: null,
        bin: null,
        contactPerson: null,
        createdBy: null,
        createdDate: null,
        email: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        legalAddress: null,
        name: null,
        telephone: null,
      },
      defaultItem: {
        actualAddress: null,
        bin: null,
        contactPerson: null,
        createdBy: null,
        createdDate: null,
        email: null,
        lastModifiedBy: null,
        lastModifiedDate: null,
        legalAddress: null,
        name: null,
        telephone: null,
        type: null,
      },
      typeList: [
        { label: 'АО', prop: 'CORPORATION' },
        { label: 'ТОО', prop: 'BUSINESS' },
        { label: 'ИП', prop: 'INDIVIDUAL' },
      ],
      userCreated: null,
      userModified: null,
      rules: [
        (v) => !!v || 'Укажите БИН',
        (v) => (v && v.toString().length === 12) || 'БИН должен состоять из 12 символов',
      ],
    };
  },
  computed: {
    suppliers() {
      return this.$store.getters['entity/getEntity']('suppliers');
    },
  },
  watch: {
    visible: {
      async handler(val) {
        if (val) {
          this.item = { ...this.defaultItem };
          if (this.editId > -1) {
            this.item = { ...this.suppliers.find((i) => i.id === this.editId) };
            this.setUserInformation();
          }
        }
      },
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    save() {
      if (this.validate()) {
        let phoneNumber = `${this.item.telephone.replace(/[-, ]/gm, '')}`;
        if (phoneNumber.length === 10) {
          phoneNumber = `7${phoneNumber}`;
        }
        const body = {
          ...this.item,
          telephone: phoneNumber,
        };
        this.loading = true;
        let method;
        if (this.editId > -1) {
          method = 'put';
        } else {
          method = 'post';
        }
        this.$api[method]('/api/v1/crm/suppliers', body).then(() => {
          this.$emit('saved');
          this.$emit('closed');
          this.reset();
        }).catch((e) => {
          this.$log.warn(e);
        }).finally(() => {
          this.loading = false;
        });
      }
    },

  },
};
</script>

<style lang="scss">
</style>
