<template>
  <v-dialog max-width="500" width="500" v-model="visible"
            @keydown.esc="$emit('closed')"
            @click:outside="$emit('closed')">
    <v-card class="requisite">
      <v-card-title class="requisite__title">
        <label class="requisite__title-label"> {{title}} </label>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form" style="margin-bottom: 30px">
          <v-select v-model="item.bank"
                    :items="banks"
                    label="Банк"
                    item-text="name"
                    return-object
                    :rules="bankRules"
                    :readonly="mode === 'info'"
          ></v-select>
          <v-text-field label="Кбе"
                        v-model="item.kbe"
                        :rules="kbeRules"
                        v-mask="'##'"
                        @keypress="onlyNumber"
                        :readonly="mode === 'info'"
          />
          <v-text-field label="БИК"
                        v-model="item.bank.bik"
                        :readonly="mode === 'info'"
          />
          <v-text-field label="IBAN"
                        v-model="item.iban"
                        :rules="ibanRules"
                        v-mask="'XXXXXXXXXXXXXXXXXXXX'"
                        :readonly="mode === 'info'"
          />
          <v-select v-model="item.currency"
                    :items="currencyList"
                    item-value="nameRu"
                    item-text="nameRu"
                    :rules="currencyRules"
                    label="Валюта счета"
                    :readonly="mode === 'info'"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-row style="height: 55px" justify="end" align="center">
          <v-btn text small @click="cancel">
            ОТМЕНИТЬ
          </v-btn>
          <v-btn small color="primary"
                 :disabled="mode === 'info'"
                 rounded style="margin-right: 20px;"
                 @click="save"
          >СОХРАНИТЬ</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RequisiteForm',
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
    id: {},
    requisite: {
      default: () => {},
    },
  },
  created() {
    this.fetchBanks();
    this.fetchCurrencyList();
    if (this.requisite.id) {
      this.mode = this.requisite.mode;
      this.getRequisiteById(this.requisite.id);
    }
  },
  computed: {
    title() {
      if (!this.requisite.mode) {
        return 'Добавление реквизитов';
      }
      if (this.requisite.mode === 'edit') {
        return 'Редактирование реквизитов';
      }
      return 'Просмотр реквизитов';
    },
  },
  data() {
    return {
      method: 'post',
      valid: false,
      banks: [],
      currencyList: [],
      item: {
        bank: {
          bik: null,
        },
        currency: null,
        iban: null,
        kbe: null,
      },
      kbeRules: [
        (v) => !!v || 'Обязательное поле',
        (v) => (!!v && v.length === 2) || 'Должно быть число формата XX',
      ],
      ibanRules: [
        (v) => !!v || 'Обязательное поле',
        (v) => !!(v && v.match(/([A-Z,a-z]){2}\d{5}[0-9A-Z]{13}/g)) || 'Пример: KZ012345678901234567',
        (v) => (v || '').length <= 20 || 'Пример: KZ012345678901234567',
      ],
      currencyRules: [
        (v) => !!v || 'Обязательное поле',
      ],
      bankRules: [
        (v) => (v && v.bik !== null) || 'Обязательное поле',
      ],
      mode: null,
    };
  },
  methods: {
    onlyNumber(event) {
      const { keyCode } = event;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    fetchBanks() {
      this.$api.get('/api/v1/public/bank', {
        params: {
          page: 0,
          size: 9999
        }
      }).then((res) => {
        this.banks = [...res.content];
      });
    },
    fetchCurrencyList() {
      this.$dictionary.get('currency').then((res) => {
        this.currencyList = [...res.data];
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        const body = { ...this.item, supplierId: this.id };
        const method = this.item?.id ? 'put': 'post'
        this.$api[method]('/api/v1/crm/supplier/requisites', body).then(() => {
          this.$emit('saved');
        });
      } else {
        this.$toast.warning('Заполните корректно данные');
      }
    },
    cancel() {
      this.$emit('closed');
    },
    getRequisiteById() {
      const item = {
        ...this.$store.getters['entity/getEntity'](`supplier/${this.id}/requisites`)
          .find((i) => i.id === this.requisite.id),
      };
      this.item = { ...item, bik: item.bank.bik };
    },
  },
};
</script>

<style lang="scss">
.requisite {
  &__title {
    background-color: #E0E0E0;
    height: 58px;
  }
  &__title-label {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;

    color: #1D1D1D;

  }
}

</style>
